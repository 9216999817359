<template>
  <v-container
    class="investor-dashboard-container"
    v-if="!loading"
    id="dashboard"
    fluid
    tag="section"
  >
    <SignContractBanner></SignContractBanner>
    <!-- <CompleteProfileBanner></CompleteProfileBanner> -->
    <div>
      <h4
        data-test="Dashboard:InvestorDashboard:TitleInvestments"
        class="h5 mt-3 dark-color"
      >
        {{ $tc("my_investments") }}
      </h4>
      <span v-if="!investorHaveInvestments" class="dark-color">
        {{ $t("no_investments") }}
      </span>
      <div v-if="investments.length > 0">
        <h5 class="investment-sub-title dark-color">
          {{ $t("investment_sub_title") }}
        </h5>
        <CardFormattedCurrency
          :cardTitle="$t('invested')"
          :totalInvested="totalInvested"
        />
        <v-simple-table class="mt-3">
          <thead id="investor-table-header" class="text-center">
            <tr>
              <th
                v-for="(item, i) in headers_investments"
                :key="i"
                class="text-center py-4 investment-table-header-item"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody style="background-color: var(--white)">
            <tr
              v-for="(item, i) in investments"
              :key="i"
              class="investor-table-line"
            >
              <td class="dark-color">
                {{ item.companyName }}
              </td>
              <td class="dark-color">
                <font-awesome-icon
                  @click="company_details(item.companyId)"
                  icon="fa-solid fa-chart-column"
                  class="investment-table-details"
                  :data-test="`Dashboard:InvestorDashboard:InvestmentDetails`"
                />
              </td>
              <td class="dark-color">
                {{ item.stockQuantity }}
              </td>
              <td class="dark-color">
                {{ formatToCurrency(item.currency, item.totalInvested) }}
              </td>
              <td class="dark-color">
                {{ item.veihcleName }}
              </td>
              <td class="dark-color">
                {{ formatToCurrency(item.currency, item.currentPosition) }}
              </td>
              <td class="dark-color">
                {{ formatToCurrency(item.currency, item.earnings) }}
              </td>
              <td class="dark-color">
                {{ formatToCurrency(item.currency, item.totalPosition) }}
              </td>
              <td class="dark-color">
                {{ get_date_string(item.date) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
    <div>
      <h4 class="h5 mt-3 dark-color" id="allocations">
        {{ $t("allocation_history") }}
      </h4>
      <span v-if="!investorHaveAllocations" class="dark-color">
        {{ $t("no_allocations") }}
      </span>
      <div v-if="allocations.length > 0">
        <h5 class="allocations-sub-title dark-color">
          {{ $t("company_interest_sub_title") }}
        </h5>
        <v-simple-table class="mt-3">
          <thead id="investor-table-header" class="text-center">
            <tr>
              <th
                v-for="(item, i) in headers_allocations"
                :key="i"
                class="py-4 text-center"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody style="background-color: var(--white)">
            <tr
              v-for="(item, i) in allocations"
              :key="i"
              class="investor-table-line"
              data-test="Dashboard:InvestorDashboard:AllocationRow"
            >
              <td class="dark-color">
                {{ item.company.name }}
              </td>
              <td
                class="dark-color"
                data-test="Dashboard:InvestorDashboard:Value"
              >
                {{ formatToCurrency(item.currency, item.value) }}
              </td>
              <td
                class="dark-color"
                v-if="item.investmentVehicle.name"
                data-test="Dashboard:InvestorDashboard:Vehicle"
              >
                {{ item.investmentVehicle.name }}
              </td>
              <td
                class="dark-color"
                v-else
                data-test="Dashboard:InvestorDashboard:Vehicle"
              >
                -
              </td>
              <td class="dark-color">
                {{ get_date_string(item.timestamp) }}
              </td>

              <td
                class="dark-color"
                :data-test="`Dashboard:InvestorDashboard:Status${item.status}`"
              >
                {{ $t(`${parseStatus(item.status)}`) }}
              </td>
              <td>
                <div
                  v-if="getCommitmentTerm(item.files)"
                  class="table-item-button"
                >
                  <DxaButtonToTable
                    @btnFunction="get_doc_path(getCommitmentTerm(item.files))"
                    :title="$t('download')"
                    :loading="
                      fileDownloading == getCommitmentTerm(item.files).path
                    "
                  />
                </div>
                <span v-else class="dark-color">-</span>
              </td>
              <td v-if="click_sign_feature">
                <div
                  v-if="showFormalizationBtn(item.status)"
                  class="table-item-button"
                >
                  <DxaButtonToTable
                    :title="defineStatusButtonSignContracts(item)"
                    @btnFunction="
                      $router.push(`/requests/sign-contracts/${item.id}`)
                    "
                    data-test="Dashboard:Investor:Dashboard:Formalization"
                  />
                </div>
                <span
                  class="dark-color"
                  v-else
                  data-test="Dashboard:InvestorDashboard:NoFormalization"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </v-container>

  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
// import CompleteProfileBanner from "@/views/pages/CompleteProfileBanner";
import SignContractBanner from "@/views/pages/SignContractBanner";
import LineChart from "@/components/charts/LineChart";
import moment from "moment";
import DxaButtonToTable from "@/components/dxa/Button/DxaButtonToTable.vue";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import CardFormattedCurrency from "@/components/dxa/Currency/CardFormattedCurrency";
import { userFileEnums } from "@/shared/enums/UserFileEnums";
import { allocationStatusEnums } from "@/views/pages/allocations/AllocationStatusEnums";

export default {
  name: "InvestorDashboard",
  data: () => ({
    value: "",
    gs: new GeneralServices(),
    apiService: new ApiService(),
    investments: [],
    allocations: [],
    indices: [],
    legend: [],
    loading: false,
    fileDownloading: null,
    totalInvested: [],
    colors: [
      "#01d281",
      "#7733FF",
      "#197DFF",
      "#ff9900",
      "#ffff00",
      "#ff99ff",
      "#006622",
      "#ff0000",
      "#000000",
    ],
    investments_chart: {
      labels: [],
      datasets: [],
    },
    signatureKey: null,
    headers_allocations: [],
    click_sign_feature: false,
    formatToCurrency: formatCurrency,
    currencyEnum: CurrencyEnum,
    investorHaveAllocations: false,
    investorHaveInvestments: false,
    userFileEnums,
  }),
  components: {
    // CompleteProfileBanner,
    SignContractBanner,
    DxaButtonToTable,
    CardFormattedCurrency,
  },

  async created() {
    this.loading = true;
    this.click_sign_feature = process.env.VUE_APP_CLICKSIGN === "ON";
    this.headers_allocations = [
      this.$tc("company", 1),
      this.$t("value"),
      this.$t("vehicle_title_single"),
      this.$t("date"),
      this.$t("status"),
      this.$t("commitment_term"),
    ];

    if (this.click_sign_feature) {
      this.headers_allocations = [
        ...this.headers_allocations,
        this.$tc("formalization", 2),
      ];
    }

    await this.apiService
      .getRequest("investor/dashboard")
      .then((resp) => {
        this.investments = resp.content.investments;
        this.investorHaveInvestments = this.investments.length > 0;
        this.allocations = resp.content.allocations;
        this.investorHaveAllocations = this.allocations.length > 0;
        this.selected_investment = this.investments[0];
        this.totalInvested = resp.content.totalInvested;
        this.sum_all_investments();
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  updated() {
    if (this.$route.query.allocations == "true") {
      this.$router.replace({ query: { allocations: undefined } });
      const element = document.getElementById("allocations");
      element.scrollIntoView({ behavior: "smooth" });
    }
  },
  async mounted() {},
  computed: {
    options() {
      return {
        // legend: {
        //   display: false,
        // },
        elements: {
          line: {
            tension: 0,
          },
        },
        responsive: false,
        maintainAspectRatios: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) => {
                  return `${value
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}%`;
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return parseFloat(tooltipItems.yLabel)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          },
        },
      };
    },
    headers_investments() {
      return [
        this.$tc("company", 1),
        this.$t("details"),
        this.$t("stock_quantity"),
        this.$t("invested"),
        this.$t("vehicle_title_single"),
        this.$t("current_position"),
        this.$t("earnings"),
        this.$t("total_position"),
        this.$t("date"),
      ];
    },
  },
  methods: {
    parseStatus(requestStatus) {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: "allocation_analysis",
        [allocationStatusEnums.FORMALIZATION]: "formalization",
        [allocationStatusEnums.DENIED]: "single_declined",
        [allocationStatusEnums.PLACEMENT_REQUEST]: "allocation_request",
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.TRANSFER_FUNDS]: "transfer",
        [allocationStatusEnums.OPERATION_SETTING]: "completion_the_operation",
        [allocationStatusEnums.SENT_CAPTABLE]: "released_capture",
        [allocationStatusEnums.CANCELLED]: "round_status_canceled",
      };
      return literal[requestStatus];
    },
    get_date_string(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getCommitmentTerm(fileList) {
      return fileList.find(
        (item) => item.userFileType === this.userFileEnums.COMMITMENT_TERM
      );
    },
    get_doc_path(doc) {
      this.fileDownloading = doc.path;

      this.apiService
        .getRequest(`investor/file?id=${doc.id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", "Termo do Compromisso.pdf");
          document.body.appendChild(link);
          link.click();
          this.fileDownloading = null;
        })
        .catch((error) => {});
    },
    generate_chart_variation() {
      var i, j, initial_value;
      for (i = 0; i < this.investments_chart.datasets.length; i++) {
        initial_value = null;
        // initial_value = this.investments_chart.data.series[i][0];
        for (j = 0; j < this.investments_chart.datasets[i].data.length; j++) {
          if (initial_value == null) {
            initial_value = this.investments_chart.datasets[i].data[j];
            if (initial_value == null) {
              this.investments_chart.datasets[i].data[j] = null;
            } else {
              this.investments_chart.datasets[i].data[j] = 0;
            }
          } else {
            this.investments_chart.datasets[i].data[j] =
              (this.investments_chart.datasets[i].data[j] / initial_value - 1) *
              100;
          }
        }
      }
    },
    fill_chart() {
      this.apiService
        .getRequest("investor/chart")
        .then((resp) => {
          var history = JSON.parse(resp);
          var series,
            i,
            date,
            day,
            month,
            year,
            history_value,
            investments_length;
          investments_length = this.investments.length;

          var colorCount = 0;

          for (i = 0; i < investments_length; i++) {
            this.investments_chart.datasets.push({
              label: this.investments[i].companyName,
              backgroundColor: this.colors[colorCount],
              fill: false,
              borderColor: this.colors[colorCount],
              data: [],
            });
            colorCount++;
          }
          for (i = 0; i < this.indices.length; i++) {
            this.investments_chart.datasets.push({
              label: this.indices[i].Name,
              backgroundColor: this.colors[colorCount],
              fill: false,
              borderColor: this.colors[colorCount],
              data: [],
            });
            colorCount++;
          }

          history.forEach((s) => {
            for (i = 0; i < investments_length; i++) {
              history_value = s.filter(
                (x) => x.CompanyId == this.investments[i].companyId
              );
              if (history_value.length > 0) {
                history_value = history_value[0];
                if (history_value.Value) {
                  this.investments_chart.datasets[i].data.push(
                    history_value.Value
                  );
                } else {
                  this.investments_chart.datasets[i].data.push(null);
                }
              } else {
                this.investments_chart.datasets[i].data.push(null);
              }
            }
            for (i = 0; i < this.indices.length; i++) {
              history_value = s.filter(
                (x) => x.Type == 1 && x.Indice.Id == this.indices[i].Id
              );
              if (history_value.length > 0) {
                history_value = history_value[0];
                if (history_value.Value) {
                  this.investments_chart.datasets[
                    investments_length + i
                  ].data.push(history_value.Value);
                } else {
                  this.investments_chart.datasets[
                    investments_length + i
                  ].data.push(null);
                }
              } else {
                this.investments_chart.datasets[
                  investments_length + i
                ].data.push(null);
              }
            }
            date = new Date(s[0].Date.replace("T", " "));
            day = date.getUTCDate().toString().padStart(2, "0");
            month = (date.getMonth() + 1).toString().padStart(2, "0");
            year = date.getFullYear().toString();
            this.investments_chart.labels.push(`${day}/${month}/${year}`);
          });
          this.generate_chart_variation();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    get_stock_buy_value(stockId, stockHistory) {
      for (var i = 0; i < stockHistory.length; i++) {
        if (stockHistory[i].StockTypeId == stockId) {
          return stockHistory[i].Value;
        }
      }
    },
    company_details: function (company_id) {
      this.$router.push({
        path: `/companies/${company_id}`,
        params: { company_id: company_id },
        query: { tab: 1 },
      });
    },
    defineStatusButtonSignContracts(allocation) {
      if (allocation.hasDocumentsToSign) return this.$tc("sign_contract", 2);
      else return this.$t("see_details");
    },
    showFormalizationBtn(status) {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.FORMALIZATION]: true,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.CANCELLED]: false,
      };

      return literal[status];
    },
  },
};
</script>

<style lang="scss" scoped>
#investor-table-header {
  background-color: var(--primary);
}

#investor-table-header > tr th {
  font-size: 1.2rem;
  color: var(--dark);
  font-weight: bold;
}

.investor-table-line > td {
  text-align: center;
  height: 60px !important;
}

.investment-sub-title,
.allocations-sub-title {
  font-family: "Source Sans Pro";
  color: #535353;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.investment-table-details {
  color: var(--primary);
  width: 32px;
  height: 22px;
  cursor: pointer;
  padding: 4px;
}

.table-item-button {
  max-width: 170px;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
}

.investor-dashboard-container {
  padding: 0 32px;
}

.dark-color {
  color: var(--dark) !important;
}

@media only screen and (max-width: 768px) {
  .dashboard-label {
    font-size: 1.8rem;
  }

  .dashboard-value {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .dashboard-table-head {
    padding: 5px;
    height: 10px;
  }
}
</style>
